@tailwind base;
@tailwind components;
@tailwind utilities;

.vertical-timeline.vertical-timeline-custom-line::before {
  background: rgb(31, 41, 55);
}

.vertical-timeline-custom-bg .vertical-timeline-element-content {
  background: rgb(31, 41, 55);
}

.vertical-timeline-element-date {
  color: rgb(156, 163, 175);
}

.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #34D399;
}

.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--education .vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #c2185b;
}

.vertical-timeline-element--no-children .vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #fff;
}

.vertical-timeline-element-custom-content .vertical-timeline-element-content {
  box-shadow: 0 3px rgb(15, 20, 27);
}

.vertical-timeline-element-custom-content .vertical-timeline-element-content-arrow {
  border-right: 7px solid rgb(31, 41, 55);
}

ul.vertical-timeline-element-custom-content li.vertical-timeline-element-list-item::before {
  content: "\00BB";
  color: rgb(156, 163, 175);
  font-weight: bold;
  font-size: 1em;
  display: inline-block;
  width: 1.15em;
  margin-top: 0.5em;
}

.vertical-timeline-element-list-item.vertical-timeline-element-clickable-list-item {
  cursor: pointer;
}

.vertical-timeline-element-list-item.vertical-timeline-element-clickable-list-item:hover {
  color: rgb(52, 211, 153);
}

.project-card {
  margin: 0.5rem 0;
  width: 100%;
  cursor: pointer;
}

.project-card:hover {
  background-color: inherit;
}

.react-pdf__Page__textContent {
  display: none;
}

/* .scroll-margin-top {
  scroll-margin-top: 0px;
} */

.scroll-margin-top {
  scroll-margin-top: 72px;
}

@media only screen and (min-width: 1024px) {
  .project-card {
    margin: 0.5rem;
    width: calc(50% - 1rem);
  }
}